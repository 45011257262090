import { AppBar, Container, Toolbar } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import Link from 'next/link';
import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { msg } from '../messages';
import { getSiteImg } from '../util';
import Category from './Category';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#233374'
    },
    logo: {
        maxWidth: 144,
        [theme.breakpoints.down('xs')]: {
            // display: "none"
            maxWidth: 100,
        },
    },
    toolbar: {
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 10
        },
    },
    logoContainer: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "right"
        },
    },
    category: {
        textAlign: "left"
    },
    container: {
        [theme.breakpoints.down('lg')]: {
            padding: 0,
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        width: '100%',
    },
}));

export default function SearchAppBar(props) {
    const classes = useStyles();

    const [suggestions, setSuggestions] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [suggestionSelected, setSuggestionSelected] = useState(false)

    const onSuggestionsFetchRequested = ({ value, reason }) => {

        if (value.length < 2) {
            return
        }

        axios.get('/api/search/' + value)
            .then(function (response) {
                setSuggestions(response.data)
            }).catch(function (error) {
                // handle error
                console.log(error);
            }).then(function () {
            })
    }

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        setSuggestionSelected(true)
        window.location.href = suggestion.link;
    }

    const onChange = (event, { newValue, method }) => {
        setSearchValue(newValue)
    };

    const getSuggestionValue = (suggestion) => {
        return suggestion.title
    }

    const renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.title}</span>
        );
    }

    const _handleKeyPress = (e) => {
        if (e.key === 'Enter' && !suggestionSelected) {
            window.location.href = "/fts/" + encodeURIComponent(searchValue)
        }
    }

    const renderInputComponent = (inputProps) => {
        return (
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search', 'onKeyPress': _handleKeyPress, ...inputProps }}
                />
            </div>
        )
    }


    const inputProps = {
        placeholder: msg.product_search,
        value: searchValue,
        onChange: onChange
    }

    return (

        <AppBar position="absolute" className={classes.root}>
            <Toolbar>
                <Container className={classes.container}>
                    <Grid container spacing={1} alignItems="center" className={classes.toolbar}>
                        <Grid item xs={6} sm={4} md={4} className={classes.category}>
                            <Category cats={props.cats}></Category>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} className={classes.logoContainer}>
                            <Link href="/" prefetch={false}>
                                <a><img src={getSiteImg("logo-big.png")}
                                    width="144px"
                                    height="64px"
                                    alt="logo"
                                    className={classes.logo}
                                    loading="lazy"
                                    decoding="async" /></a>
                            </Link>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <div>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    onSuggestionSelected={onSuggestionSelected}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    renderInputComponent={renderInputComponent}
                                />
                            </div>
                        </Grid>

                    </Grid>

                </Container>


            </Toolbar >

            <style jsx global>{`
                .react-autosuggest__container {
                    position: relative;
                    width: auto;
                }
                  
                .react-autosuggest__input {
                    width: 100%;
                    height: 30px;
                    padding: 5px 20px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 300;
                    font-size: 1rem;
                    border: 1px solid  rgba(255, 255, 255, 0.15);
                    border-radius: 4px;
                    background-color: rgba(255, 255, 255, 0.15);
                    color: currentColor;
                }
                
                .react-autosuggest__input::placeholder {
                    color: #B8B8B8;
                }
                  
                .react-autosuggest__input--focused {
                    outline: none;
                }
                  
                .react-autosuggest__input--open {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                  
                .react-autosuggest__suggestions-container {
                    display: none;
                }
                  
                .react-autosuggest__suggestions-container--open {
                    display: block;
                    position: absolute;
                    width: 100%;
                    border: 1px solid #aaa;
                    background-color: #fff;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 300;
                    font-size: 1rem;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    z-index: 2;
                    text-align: left;
                }
                  
                .react-autosuggest__suggestions-list {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    color: black;
                }
                  
                .react-autosuggest__suggestion {
                    cursor: pointer;
                    padding: 10px 20px;
                }
                  
                .react-autosuggest__suggestion--highlighted {
                    background-color: #ddd;
                }

                @media (max-width: 600px) {
                    .react-autosuggest__input {
                            width: 100%;
                    }
            
                    .react-autosuggest__suggestions-container--open {
                            width: 100%;
                            font-size: 12px;
                    }
                    
                }
            `}</style>
        </AppBar >
    );
}