let domain_text = "Yorumsepeti.co"
export const msg = {
    "product_search": "Ürün ara...",

    "home_page_title": domain_text + " - İnternetteki Bütün Ürün Yorumları",
    "home_page_desc": "İnternetteki bütün ürünlerin kullanıcı yorumları " + domain_text + "'da.",
    "home_page_canonical": "https://yorumsepeti.co",
    "home_page_landing_title": "Tüm Ürünlerin Kullanıcı Yorumları",
    "home_page_landing_desc": "<b>" + domain_text + "</b> Onlarca siteden topladığı Binlerce ürünün Milyonlarca yorumunu analiz edip en faydalıları sana gösterir.",


    "user_review_appendix": "kullanıcı yorumu",
    "user_review_appendix_1": "kullanıcı yorumu",
    "user_review_appendix_2_3_4": "kullanıcı yorumu",
    "user_review_expansian_panel_appendix": "- Kullanıcı Yorumları",
    "read_reviews_button_text": "Yorumları oku",
    "infinite_loop_loading": "Yükleniyor...",
    "infinite_loop_end": "Son.",
    "price_with_currency": "x_price_x",

    "expansion_details_text": domain_text + " <b>x_title_x kullanıcı yorumları</b> için bütün interneti taradı.<br />İnternetteki bütün <b>x_title_x yorumları</b> ve değerlendirmelerini bu sayfada görebilirsin.",

    "product_page_title_appendix": "Kullanıcı Yorumları - " + domain_text,
    "product_page_desc": "x_title_x kullanıcı yorumları, x_title_x yorumları, x_title_x değerlendirmeleri",

    "why_good": "NEDEN İYİ",
    "why_bad": "NEDEN KÖTÜ",
    "read_more": "Devamını oku..",
    "more": "Daha Fazla",
    "review": "Yorum",
    "review_1": "Yorum",
    "review_2_3_4": "Yorum",
    "similar_items": "Benzer Ürünler",
    "list_bests_in_the_cat": "Bu kategorinin en popülerlerine git!",
    "read_before_buying": "Almadan önce bilmen gerekenleri oku!",
    "no_review": "Bu ürün için yorum bulunmamaktadır. Aşağıdan bu kategorideki diğer ürünlere göz atabilirsiniz.",

    "terms_and_cond": "Kullanım Koşulları",
    "privacy_policy": "Gizlilik Sözleşmesi",

    "categories": "Kategoriler",
    "back": "Geri",
    "home": "Anasayfa",
    "most_review": "En Çok Yorum",
    "most_rated": "En Yüksek Puan",
    "most_expensive": "En Pahalı",
    "most_cheap": "En Ucuz",
    "read_about_cat": "Bu ürünler hakkında bilmen gerekenleri öğrenmek için tıkla!",

    "product_page": {
        "info": "Bilgi",
        "go_to_comments": "Yorumları okumak için tıkla.",
        "analyse": "Analiz",
        "general_stats": "<b>x_title_x</b> için <b>x_sitecount_x</b> e-ticaret sitesinde kullanıcılar tarafından toplam <b>x_reviewcount_x</b> yorum yapılmış ve ortalama <b>x_avgstar_x</b> puan verilmiş.",
        "stats_by_sellers": "Satıcılara göre istatistikler ise şöyle;",
        "seller_stats": "<b>x_sitename_x</b> üzerinde <b>x_reviewcount_x</b> kullanıcı yorumu yapılmış ve ortalama <b>x_avgstar_x</b> puana sahip.",
        "go_to_seller": "Bu satıcıya gitmek için tıkla.",
        "detail": "Detay",
        "go_to_category": "Bu kategorideki bütün ürünleri incelemek için tıkla.",
        "go_to_article": "Bu ürünü almadan önce bilmen gereken her şeyi 3 dakikada öğrenmek için tıkla.",
        "recommended_seller": "Tavsiye Edilen Satıcı",
        "go_to_recommended_seller": "Önerilen Satıcıya Git",
        "bests_in_this_cat": "Kategorinin En İyileri",
        "buyers_guide": "Almadan Önce Bilmen Gerekenler",
    },
    "article": {
        "last_update": "<strong>Son Güncelleme:</strong> x_date_x",
        "content_text": "İçerik",
        "related_articles": "İlgili Makaleler",
        "show_items_in_cat": "Bu Kategorideki Diğer Ürünler",
        "dynamic_section_title": "Yorumsepeti x_keyword_x İncelemesi",
        "dynamic_section_summary": `Yorumsepeti internetteki E-ticaret sitelerini tarayarak x_keyword_x hakkında yapılan bütün
                                    kullanıcı yorumlarını analiz etti. Bu analiz sonucunda tespit edilen
                                    fiyat performans olarak en çok beğenilen ürünü ve en kaliteli ürünü aşağıda görebilirsin.`,

        "dynamic_section_fp_desc": `<b> Fiyat Performans x_keyword_x</b> arayanlar için Yorumsepeti'nin seçtiği ürün
                                    <a href=\"/x_product_link_x\"> x_product_name_x</a>.
                                    E-ticaret sitelerinde <b>x_product_name_x</b> için toplam <b>x_review_count_x</b> kullanıcı
                                    yorumu yapılmış ve 5 üzerinden ortalama <b>x_avg_star_x</b> puan verilmiş. Fiyatının uygunluğu ve kullanıcı yorumlarının
                                    analizinden çıkartılan sonuca göre, bu ürün için tam bir fiyat performans diyebiliriz.`,

        "dynamic_section_bp_desc": `<b>En İyi x_keyword_x</b> arayanlar için Yorumsepeti'nin seçtiği ürün
                                    <a href=\"/x_product_link_x\"> x_product_name_x</a>.
                                    E-ticaret sitelerinde <b>x_product_name_x</b> için toplam <b>x_review_count_x</b> kullanıcı
                                    yorumu yapılmış ve 5 üzerinden ortalama <b>x_avg_star_x</b> puan verilmiş. Rakiplerinden bir miktar pahalı olmasına rağmen
                                    kullanıcı yorumlarının analizlerine göre kalitesiyle parasını hakettiği görülüyor.`,

        "items_in_cat_text": `Yorumsepeti senin için <a href=\"/x_cat_link_x\"> bu kategorideki bütün ürünleri ve kullanıcı yorumlarını</a> 
                                bir araya topladı;`,

    },

    "search_page": {
        "info": "\"<b>x_search_term_x</b>\" araması için toplam <b>x_res_count_x</b> sonuç bulundu."
    }
}