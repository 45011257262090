import React, { useState, useEffect } from 'react';
import { SlideMenu } from 'primereact/slidemenu';
import { Button } from 'primereact/button';
import { msg } from '../messages'


export default function Category(props) {

    const items = props.cats
    let menu = null

    // bu kodu okumaya calisma. Canim sıkıldı böyle yazdım. sanane
    if (items !== null) {
        items.map(i => {
            if (i.url === undefined) {
                i.command = () => { document.getElementsByClassName("p-slidemenu-content")[0].scrollTo(0, 0) };

                if (i.items !== undefined) {
                    i.items.map(j => {
                        j.command = () => { document.getElementsByClassName("p-slidemenu-content")[0].scrollTo(0, 0) };

                        if (j.items !== undefined) {
                            j.items.map(k => {
                                k.command = () => { document.getElementsByClassName("p-slidemenu-content")[0].scrollTo(0, 0) };

                                if (k.items !== undefined) {
                                    k.items.map(l => {
                                        l.command = () => { document.getElementsByClassName("p-slidemenu-content")[0].scrollTo(0, 0) };

                                        if (l.items !== undefined) {
                                            l.items.map(m => {
                                                m.command = () => { document.getElementsByClassName("p-slidemenu-content")[0].scrollTo(0, 0) };

                                                if (m.items !== undefined) {
                                                    m.items.map(n => {
                                                        n.command = () => { document.getElementsByClassName("p-slidemenu-content")[0].scrollTo(0, 0) };
                                                    })
                                                }

                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            }
        });
    }


    const [a, setA] = useState();
    useEffect(() => {
        setA(<React.Fragment>
            <SlideMenu
                appendTo={document.body}
                ref={(el) => menu = el}
                model={items}
                popup
                viewportHeight={500}
                menuWidth={200}
                backLabel={msg.back}
                baseZIndex={1111}
                style={{ marginTop: 5 }}
                onShow={changePosition}
            >
            </SlideMenu>
            <Button type="button" icon="pi pi-bars" label={msg.categories} onClick={(event) => menu.toggle(event)}></Button>
        </React.Fragment>)
    }, []);

    useEffect(() => {
        // Geri butonuna basınca menuyu en basa scroll etmek icin yapılan hacky yontem. PrimeReact'ta bug var baska yonten bulamadım.
        let checkExist = setInterval(function () {
            if (document.getElementsByClassName("p-slidemenu-backward").length) {
                document.getElementsByClassName("p-slidemenu-backward")[0].addEventListener('click', function () {
                    document.getElementsByClassName("p-slidemenu-content")[0].scrollTo(0, 0)
                })
                clearInterval(checkExist);
            }
        }, 1000);
    }, [])


    const changePosition = () => {
        let menu = document.getElementsByClassName("p-slidemenu")[0]
        menu.style.position = "fixed"
        menu.style.top = "45px"
    }

    return (<div>{a}</div>)
}