import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { msg } from '../messages';

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        flexGrow: 1,
        backgroundColor: '#233374',
        height: 22
    },
    aa: {
        color: "gray",
        fontSize: "10px",
        textDecoration: "none",
        marginRight: "25px",
        '&:hover': {
            color: "white",
        }
    },
    last_aa: {
        color: "gray",
        fontSize: "10px",
        textDecoration: "none",
        '&:hover': {
            color: "white",
        }
    }
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <AppBar style={{ position: "absolute", bottom: 0, height: 23 }} color="primary" className={classes.appBar}>
            <div>
                <a className={classes.aa} href="/tos">{msg.terms_and_cond}</a>
                <a className={classes.last_aa} href="/privacy">{msg.privacy_policy}</a>
            </div>
        </AppBar>
    );
}
