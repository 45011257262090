import { msg } from './messages'
import { lang, prod_url, site_img_path, user_review_url_text } from './settings'

export const getSiteImg = (img_name) => {
    return site_img_path + img_name
}

export const titleToLink = (title, id) => {
    var returnString = title.toLowerCase();
    //Convert Characters
    returnString = returnString.replace(/ö/g, 'o');
    returnString = returnString.replace(/ç/g, 'c');
    returnString = returnString.replace(/ş/g, 's');
    returnString = returnString.replace(/ı/g, 'i');
    returnString = returnString.replace(/ğ/g, 'g');
    returnString = returnString.replace(/ü/g, 'u');
    returnString = returnString.replace(/ä/g, 'ae');
    returnString = returnString.replace(/ß/g, 'ss');
    returnString = returnString.replace(/ł/g, 'l');
    returnString = returnString.replace(/ć/g, 'c');
    returnString = returnString.replace(/ą/g, 'a');
    returnString = returnString.replace(/ę/g, 'e');
    returnString = returnString.replace(/ś/g, 's');
    returnString = returnString.replace(/ó/g, 'o');
    returnString = returnString.replace(/ń/g, 'n');
    returnString = returnString.replace(/ź/g, 'z');
    returnString = returnString.replace(/ż/g, 'z');

    // if there are other invalid chars, convert them into blank spaces
    returnString = returnString.replace(/[^a-z0-9\s-]/g, "");
    // convert multiple spaces and hyphens into one space       
    returnString = returnString.replace(/[\s-]+/g, " ");
    // trims current string
    returnString = returnString.replace(/^\s+|\s+$/g, "");
    // add hyphens
    returnString = returnString.replace(/\s/g, "-");

    returnString = returnString + user_review_url_text + id

    return returnString
}

export const truncateText = (text, max_length) => {
    return (text.length > max_length) ? text.substr(0, max_length - 1) + '...' : text;
}

export const sanitizeTitle = (title) => {
    let res = ""
    try {
        res = decodeURIComponent(JSON.parse(title))
    } catch (error) {
        // check if title has % char.
        if (title.indexOf("%") > -1)
            title = title.replace(/%/g, "%25")

        try {
            res = decodeURIComponent(JSON.parse(title))
        } catch (error) {
            console.log("Deadly Err:" + error + " title: " + title)
        }
    }
    return res
}

export const getProductPageTitle = (title) => {
    return sanitizeTitle(title) + " " + msg.product_page_title_appendix
}

export const getProductPageDesc = (title) => {
    return msg.product_page_desc.replace(/x_title_x/g, sanitizeTitle(title))
}

export const getProductPageCanonical = (product_url_path) => {
    return msg.home_page_canonical + "/" + product_url_path
}

export const getCategoryPageTitle = (title) => {
    return title + " " + msg.product_page_title_appendix
}

export const getCategoryPageDesc = (title) => {
    return msg.product_page_desc.replace(/x_title_x/g, title)
}

export const getCategoryPageCanonical = (cat_page_url) => {
    return msg.home_page_canonical + "/" + cat_page_url
}

export const getArticlePageCanonical = (article_page_url) => {
    return msg.home_page_canonical + "/" + article_page_url
}

export const getExpansionDetailText = (title) => {
    return msg.expansion_details_text.replace(/x_title_x/g, title)
}

export const timestampToDate = (timestamp) => {
    var date = new Date(timestamp * 1000);
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
}

export const commentArrToText = (comments) => {
    var text = ""
    for (var i = 0; i < comments.length; i++) {
        if (text !== "")
            text += " "
        text += comments[i]
    }
    return text
}

export const clearComment = (comment) => {
    comment = comment.replace(/<br \/>|\r|\n/g, '');
    comment = decode(comment)
    return comment
}

/**
 * Converts an html characterSet into its original character.
 *
 * @param {String} str htmlSet entities
 **/
export const decode = (str) => {
    return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
}

export const prepareComment = (comments) => {
    var comment = ""
    comment = commentArrToText(comments)
    comment = clearComment(comment)
    return comment
}

export const getShopImg = (shop_link) => {
    return getSiteImg(extractHostname(shop_link) + '.png')
}

export const getJsonLDForArticlePage = (article) => {
    return {
        "@type": "NewsArticle",
        "name": article.title,
        "headline": article.title,
        "alternativeHeadline": article.title,
        "description": article.meta.desc,
        "articleBody": article.summary,
        "datePublished": new Date(article.created_at).toISOString(),
        "dateModified": new Date(article.updated_at).toISOString(),
        "image": [article.img.big, article.img.small],
        "author": {
            "@type": "Person",
            "name": prod_url.split("https://")[1].split("/")[0]
        },
        "publisher": {
            "@type": "Organization",
            "name": prod_url.split("https://")[1].split("/")[0],
            "url": prod_url,
            "logo": {
                "@type": "ImageObject",
                "url": site_img_path + "logo-big.png",
            }
        },
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": prod_url + article.meta.canonical_url
        },
        "url": prod_url + article.meta.canonical_url,
        "thumbnailUrl": article.img.small,
        // "articleSection": article.,
        "@context": "http://schema.org"
    }
}

export const getJsonLDForSearch = () => {
    return {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": prod_url,
        "potentialAction": {
            "@type": "SearchAction",
            "target": prod_url + "fts/" + "{search_term_string}",
            "query-input": "required name=search_term_string"
        }
    }
}

export const getJsonLD = (title, img_url, review_count, avg_star) => {
    return {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": title,
        "image": [img_url],
        "description": title + " " + get_user_review_msg(review_count),
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": String(avg_star),
            "reviewCount": String(review_count),
            "bestRating": "5",
            "worstRating": "1"
        }
    }

    /*
    Buraya şunlar eklenecek hazır olduğunda;
    1- 
    https://developers.google.com/search/docs/data-types/product "gtin8 | gtin13 | gtin14 | mpn | isbn" yada "sku"
    
    2- 
    "brand": {
        "@type": "Thing",
        "name": "Jabra"
    }
    
    3-
    "offers": {
        "@type": "AggregateOffer",
        "offerCount": "38",
        "lowPrice": "83.92",
        "priceCurrency": "TRY",
        "offers": [
            {
                "@type": "Offer",
                "availability": "http://schema.org/InStock",
                "price": "89.9",
                "priceCurrency": "TRY",
                "url": "nourl",
                "seller": "N11"
            },
            {
                "@type": "Offer",
                "availability": "http://schema.org/InStock",
                "price": "81",
                "priceCurrency": "TRY",
                "url": "https://www.akakce.com/siparis/?p=700101188",
                "seller": "Welldermo"
            }
        ]
    },
    */
}

const extractHostname = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }

    if (url.indexOf("www.") > -1) {
        hostname = url.split('www.')[1];
    }

    if (url.indexOf("urun.") > -1) {
        hostname = url.split('urun.')[1];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    hostname = hostname.split(".")
    return hostname[0];
}

const get_cat_sub_item = (item) => {
    let i = {}
    if (item["items"] !== undefined) {
        i = {
            "label": item["label"],
            "url": item["items"][0]["url"]
        }
    }
    else {
        i = {
            "label": item["label"],
            "url": item["url"]
        }
    }
    return i
}

export const find_cat_path = (items, cat_id) => {
    for (const item of items) {
        let i = get_cat_sub_item(item)

        if (parseInt(item.id) === parseInt(cat_id)) {

            if (item.items !== undefined) {
                i["items"] = []
                for (const sub_item of item.items) {
                    let j = get_cat_sub_item(sub_item)
                    i["items"].push(j)
                }
            }

            return [i]
        }
        else if (item["items"] !== undefined) {
            let path = find_cat_path(item["items"], cat_id)
            if (path !== undefined) {
                return [i, ...path];
            }
        }
    }
};

export const get_affiliate_link = (link) => {
    let host_name = extractHostname(link)

    switch (host_name) {
        case "trendyol":
            return "https://tr.rdrtr.com/aff_c?offer_id=5680&aff_id=27180&source=yorumsepeti.co&url="
                + encodeURIComponent(link)
                + "%3Futm_source%3Daff_t%26utm_medium%3Dcps%26utm_campaign%3Dgelirortaklari%26utm_subaff%3D%7Baff_id%7D%26"
                + "adjust_tracker%3D21ouxa_bfy1cc%26adjust_campaign%3Dperformics_tr%26adjust_adgroup%3D1%26adjust_label%3D%7B"
                + "transaction_id%7D%26pfx%3D%7Btransaction_id%7D%26utm_term%3D%7Btransaction_id%7D"
        case "morhipo":
            return "https://tr.rdrtr.com/aff_c?offer_id=2124&aff_id=27180&source=yorumsepeti.co&url="
                + encodeURIComponent(link)
                + "%26utm_source%3Dgelirortaklari%26utm_medium%3Daffiliate%26utm_campaign%3D{aff_id}"
                + "%26pfx%3D{transaction_id}%26utm_term%3D{transaction_id}"
        case "amazon":
            if (lang === "tr") {
                return "https://tr.rdrtr.com/aff_c?offer_id=6718&aff_id=27180&source=yorumsepeti.co&url="
                    + encodeURIComponent(link)
                    + "?tag=yorumsepeti.co-21&tid=yorumsepeti.co-21"
            }
            return link
        default:
            return link;
    }
}

export const get_review_msg = (review_count) => {
    let last_digit = review_count % 10

    if (review_count == 1)
        return msg.review_1
    else if ((review_count > 14 || review_count < 10) && (last_digit == 2 || last_digit == 3 || last_digit == 4))
        return msg.review_2_3_4
    else
        return msg.review
}

export const get_user_review_msg = (review_count) => {
    let last_digit = review_count % 10

    if (review_count == 1)
        return msg.user_review_appendix_1
    else if ((review_count > 14 || review_count < 10) && (last_digit == 2 || last_digit == 3 || last_digit == 4))
        return msg.user_review_appendix_2_3_4
    else
        return msg.user_review_appendix
}

export const get_price_with_currency = (price) => {
    if (price == null || price == 0)
        return ""
    return msg.price_with_currency.replace(/x_price_x/g, String(price))
}